header {
  background-color: #f5ba13;
  margin: 0;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 0;
}

header h1 {
  color: #fff;
  font-family: 'Genos', sans-serif;
  font-weight: 300;
  font-size: 30px;
  margin: 0;
  padding: 10px 0;
}

.headerMenu{
  margin: 0;
  padding: 0;
  background-color: #fcc839;

}