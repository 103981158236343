.feedbackListContainer {
    /* text-align: center; */
    margin: 10px auto;
    max-width: 800px;
  }
  .feedbackEntryContainer {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 100ms ease-in-out;
  }
  
  input[type="checkbox"]{
  
    width              : 30px;
    height             : 30px;
    margin             : 0;
    vertical-align     : top;
    border             : 2px solid #ddd;
    border-radius      : 4px;
    background         : #fff no-repeat center center;
  }
  
  .readMoreBtn{
    background-color: #fdfdfd; 
    border: none;
    color: rgb(121, 121, 121);
    padding: 3px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .deleteBtn, .saveBtn{
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    margin: 8px 0;
  }
  
  .deleteBtn{
    background-color: #e6746c; 
    color: rgb(78, 8, 8);
  }
  
  .saveBtn{
    background-color: #6ce6c1; 
    color: rgb(8, 78, 14);
  }
   
  /* .feedbackListContainerHead{
  
  } */
  
  .flex-container {
    display: flex;
  }

  .flex-child:first-child {
    /* width: 50px; */
    padding-left: 1rem;
    padding: 8px 1rem;
  
  } 
  
  .flex-child {
    /* flex: 1; */
    /* height: 60px; */
    /* border: 2px solid yellow; */
    line-height: 2.2;
    padding-left: 1rem;
  }  
  
  .flex-child:last-child {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    /* margin-left: auto; */
    margin: auto 4px auto auto;
  
  } 