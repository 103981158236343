label {
    display: block;
    margin-top: 10px;
}

.card-container {
    max-width: 350px !important;
    padding: 40px 40px;
    background-color: #f7f7f7;
    box-sizing: border-box;
    margin: 50px auto;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}


.form-control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-transform: none;
    font-weight: 400;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: block;
    cursor: pointer;
    width: 100%;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: .75rem 1.25rem;   
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
} 

.form-group {
  margin-bottom: 1rem;
}

