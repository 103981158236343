footer {
    /* position: absolute; */
    text-align: center;
    /* bottom: 0; */
    /* width: 100%; */
    /* height: 2.5rem; */
  }
  
  footer p {
    /* color: #ccc; */
  }